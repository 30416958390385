import { Center, Loader, Modal } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    useGetApiResourceDetailsById,
    useGetApiResourceRoles,
    useGetApiResourceScopes,
    useGetTokenExchangeClients
} from 'api/v3/api-resources/api-resources';
import {
    BasicApiResourceRoleModelApiResponse,
    BasicApiResourceScopeModelApiResponse,
    BasicClientModelApiResponse,
    DetailApiResourceModel
} from 'api/v3/models';
import { CreateApi } from 'modules/applications/api/CreateApi';
import { usePermissions } from 'modules/shared/permissions';
import { ItemCardAction } from 'shared/components/ItemCardAction';

type Props = {
    apiId: string;
};

export const DuplicateApiAction = ({ apiId }: Props) => {
    const { t } = useTranslation();
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const canCreate = usePermissions('Create');

    if (!canCreate) {
        return null;
    }

    return (
        <>
            <ItemCardAction icon={<IconCopy />} title={t('duplicateItem', { itemType: t('api') })} onClick={() => setIsDuplicateModalOpen(true)} />
            {isDuplicateModalOpen && <DuplicateApiModal apiId={apiId} onClose={() => setIsDuplicateModalOpen(false)} />}
        </>
    );
};

type DuplicateApiModalProps = {
    apiId: string;
    onClose: () => void;
};

const DuplicateApiModal = ({ apiId, onClose: handleClose }: DuplicateApiModalProps) => {
    const { t } = useTranslation();

    const { data: fullApiResponse } = useGetApiResourceDetailsById(apiId, undefined);
    const { data: scopes } = useGetApiResourceScopes(apiId, undefined);
    const { data: roles } = useGetApiResourceRoles(apiId, undefined);
    const { data: tokenExchangeClients } = useGetTokenExchangeClients(apiId, undefined);

    const duplicatedApiDetails: DetailApiResourceModel | undefined = fullApiResponse && {
        ...fullApiResponse.data,
        id: '',
        displayName: `${fullApiResponse.data.displayName} - copy`,
        applicationId: fullApiResponse.data.applicationId
    };

    const duplicatedScopes: BasicApiResourceScopeModelApiResponse[] | undefined =
        scopes &&
        scopes.data.map((scope) => ({
            ...scope,
            data: {
                ...scope.data,
                id: '',
                name: scope.data.name.toLowerCase().replace(`${fullApiResponse?.data.name.toLowerCase()}/`, '')
            }
        }));

    const duplicatedRoles: BasicApiResourceRoleModelApiResponse[] | undefined =
        roles &&
        roles.data.map((role) => ({
            ...role,
            data: {
                ...role.data,
                id: '',
                name: role.data.name.toLowerCase().replace(`${fullApiResponse?.data.name.toLowerCase()}/`, '')
            }
        }));

    const duplicateTokenExchangeClients: BasicClientModelApiResponse[] | undefined =
        tokenExchangeClients &&
        tokenExchangeClients.data.map((client) => ({
            ...client,
            data: {
                ...client.data,
                name: client.data.name
            }
        }));

    return (
        <Modal
            closeOnClickOutside={false}
            opened={true}
            size={1000}
            title={t('duplicateItem', { itemType: t('api') })}
            centered
            onClick={(event) => {
                event.stopPropagation();
            }}
            onClose={handleClose}
        >
            {!duplicatedApiDetails || !duplicatedScopes || !duplicatedRoles || !duplicateTokenExchangeClients ? (
                <Center>
                    <Loader />
                </Center>
            ) : (
                <CreateApi
                    initialValues={{
                        api: duplicatedApiDetails,
                        scopes: duplicatedScopes,
                        roles: duplicatedRoles,
                        tokenExchangeClients: duplicateTokenExchangeClients
                    }}
                    onCancel={handleClose}
                />
            )}
        </Modal>
    );
};
