import { Group, Button, Stepper, Tabs, Stack, Title } from '@mantine/core';
import { UseFormReturnType, zodResolver, FormErrors } from '@mantine/form';
import { IconCircleOff } from '@tabler/icons-react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from 'axios';
import { Children, ReactElement, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';

import { i18n } from 'i18n';
import { ErrorNotification } from '../errorHandling';
import { DetailFormProvider } from './DetailFormProvider';

export type DetailFormChildProps = {
    label?: ReactNode;
    header?: ReactNode;
    labelRightSection?: ReactNode;
    icon?: ReactNode;
    variant: DetailFormVariant;
    name: string;
    schemaPath?: string;
    disabled?: boolean;
    setContentReadOnly?: boolean;
    readOnly?: boolean;
    onCustomValidation?: () => FormErrors;
};

export enum DetailFormVariant {
    EDIT,
    CREATE
}

type Props = {
    onConfirm?: () => Promise<void>;
    onReset?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturnType<any, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validationSchema: z.ZodObject<any>;
    next?: ReactNode;
    back?: ReactNode;
    confirm?: ReactNode;
    variant: DetailFormVariant;
    children: ReactElement<DetailFormChildProps> | ReactElement<DetailFormChildProps>[];
    title?: string;
};

export const DetailForm = ({
    onConfirm,
    onReset,
    form,
    title,
    next = i18n.t('nextStep'),
    back = i18n.t('back'),
    confirm = i18n.t('create'),
    variant,
    validationSchema,
    children: inputChildren
}: Props) => {
    const { t } = useTranslation();

    const location = useLocation();
    const hashLink = location.hash.slice(1);

    const children = Array.isArray(inputChildren) ? inputChildren : [inputChildren];

    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState<string | object | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(children.some((child) => child.props.name === hashLink) ? hashLink : children[0].props.name);

    const childCount = Children.count(children);
    const maxIndex = childCount - 1;
    const currentChild = variant === DetailFormVariant.EDIT ? children.find((child) => child.props.name === selectedTab) : children[activeStep];

    const handleCreate = async () => {
        setError(undefined);
        setIsLoading(true);
        try {
            if (validateForm()) {
                onConfirm && (await onConfirm());
            }
        } catch (err: Error | AxiosError | unknown) {
            if (axios.isAxiosError(err)) {
                setError(err.response?.data);
            } else if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Unknown error');
            }
            if (variant === DetailFormVariant.CREATE) {
                setActiveStep(0);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleReset = () => {
        setError(undefined);
        onReset && onReset();
    };

    const validateForm = () => {
        setError(undefined);

        if (form && currentChild && currentChild.props.schemaPath && !currentChild.props.disabled) {
            const schema = validationSchema.pick({ [`${currentChild.props.schemaPath}`]: true });
            const validator = zodResolver(schema);
            let errors = validator(form.values);

            if (currentChild.props.onCustomValidation) {
                const customValidationErrors = currentChild.props.onCustomValidation();
                errors = { ...errors, ...customValidationErrors };
            }

            form.setErrors(errors);

            return Object.keys(errors).length === 0;
        }

        return true;
    };

    const activeNextStepsAvailable = children.some((value, index) => index > activeStep && !value.props.disabled);
    const activePreviousStepsAvailable = children.some((value, index) => index < activeStep && !value.props.disabled);

    const handleNext = () => {
        if (validateForm()) {
            let nextStep = activeStep;
            if (activeStep !== maxIndex) {
                nextStep++;
                for (; nextStep <= maxIndex; nextStep++) {
                    if (!children[nextStep].props.disabled) {
                        break;
                    }
                }
            }

            setActiveStep(nextStep);
        }
    };

    const handlePrev = () => {
        form.clearErrors();
        if (validateForm()) {
            let nextStep = activeStep;
            if (activeStep !== 0) {
                nextStep--;
                for (; nextStep >= 0; nextStep--) {
                    if (!children[nextStep].props.disabled) {
                        break;
                    }
                }
            }

            setActiveStep(nextStep);
        }
    };

    const handleTabChange = (value: string | null) => {
        if (value && validateForm()) {
            setSelectedTab(value);
        }
    };

    return (
        <DetailFormProvider onValidate={() => validateForm()}>
            <form
                // eslint-disable-next-line react/jsx-handler-names
                onSubmit={form.onSubmit(() => handleCreate())}
            >
                <Stack>
                    {title && <Title order={2}>{title}</Title>}
                    {childCount === 1 && currentChild && (
                        <Stack>
                            {variant === DetailFormVariant.EDIT && currentChild.props.labelRightSection && currentChild.props.labelRightSection}
                            {children}
                        </Stack>
                    )}

                    {variant === DetailFormVariant.CREATE && childCount > 1 && (
                        <Stepper active={activeStep}>
                            {!!children &&
                                children
                                    // .filter((child) => !child.props.disabled)
                                    .map((child) => (
                                        <Stepper.Step
                                            key={child.props.name}
                                            disabled={child.props.disabled}
                                            icon={child.props.disabled && <IconCircleOff color="var(--mantine-color-red-3)" size="1.1rem" />}
                                            label={child.props.label}
                                        >
                                            <Stack>
                                                <Group justify="space-between" w="100%">
                                                    {child.props.header ?? <Title order={4}>{child.props.label}</Title>}
                                                    {child.props.labelRightSection && child.props.labelRightSection}
                                                </Group>
                                                {child}
                                            </Stack>
                                        </Stepper.Step>
                                    ))}
                        </Stepper>
                    )}

                    {variant === DetailFormVariant.EDIT && childCount > 1 && (
                        <Tabs value={selectedTab} onChange={handleTabChange}>
                            <Tabs.List grow>
                                {!!children &&
                                    children.map((child) => (
                                        <Tabs.Tab
                                            key={child.props.name}
                                            disabled={child.props.disabled}
                                            leftSection={child.props.icon ?? undefined}
                                            value={child.props.name}
                                        >
                                            {child.props.label}
                                        </Tabs.Tab>
                                    ))}
                            </Tabs.List>
                            {!!children &&
                                children.map((child) => (
                                    <Tabs.Panel key={child.props.name} pt="xs" value={child.props.name}>
                                        <Stack mt="sm">
                                            <Group justify="space-between">
                                                {child.props.header ?? <Title order={4}>{child.props.label}</Title>}
                                                {child.props.labelRightSection && child.props.labelRightSection}
                                            </Group>
                                            {child}
                                        </Stack>
                                    </Tabs.Panel>
                                ))}
                        </Tabs>
                    )}
                    <ErrorNotification error={error} title={t('saving')} />
                    {onConfirm && (
                        <Group justify="flex-end" mt="xl">
                            {variant === DetailFormVariant.CREATE && (
                                <>
                                    <Button disabled={activeStep === 0 || !activePreviousStepsAvailable} variant="default" onClick={handlePrev}>
                                        {back}
                                    </Button>
                                    {activeStep !== maxIndex && activeNextStepsAvailable && <Button onClick={handleNext}>{next}</Button>}
                                    {(activeStep === maxIndex || !activeNextStepsAvailable) && (
                                        <Button loading={isLoading} type="submit">
                                            {confirm}
                                        </Button>
                                    )}
                                </>
                            )}
                            {variant === DetailFormVariant.EDIT && (
                                <>
                                    <Button disabled={!form?.isDirty()} variant="default" onClick={handleReset}>
                                        {t('reset')}
                                    </Button>
                                    <Button disabled={!form?.isDirty()} loading={isLoading} type="submit">
                                        {t('save')}
                                    </Button>
                                </>
                            )}
                        </Group>
                    )}
                </Stack>
            </form>
        </DetailFormProvider>
    );
};
